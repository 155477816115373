<template>
  <!-- account START -->
  <div class="account">

    <!-- account:personal:data START -->
    <div class="account-name">
      <h1
        v-if="userGender && userName"
        v-html="`<span>${userGender}</span><br>${userName}`"
      ></h1>
      <h1
        v-html="`
          <span class='f-like-text-loading-small'></span>
          <span class='f-like-text-loading-middle'></span>`
        "
        v-else
      ></h1>
      <router-link
        class="icon"
        :to="'/account/logout'"
      ></router-link>
    </div>
    <!-- account:personal:data END -->

    <!-- account:permalinks START -->
    <div class="permalinks">

      <!-- account:permalinks:permalink START -->
      <div
        class="permalink"
        v-for="(permalink, permalinkIndex) in permalinks"
        :key="`permalinks-permalink-${permalinkIndex}`"
      >
        <permalink
          :permalink="permalink"
        />
      </div>
      <!-- account:permalinks:permalink END -->

    </div>
    <!-- account:permalinks END -->

    <!-- theme-switch START -->
    <div
      class="el-switch-holder"
      v-on:click="reRenderComponent"
    >
      <h2 v-html="$t('switch.appmode')"></h2>

      <el-switch
        v-model="themeSwitch"
        active-color="#353635"
        inactive-color="#A0A0A0"
        :inactive-text="$t('switch.appmode.inactive')"
        :active-text="$t('switch.appmode.active')"
      ></el-switch>
    </div>
    <!-- theme-switch END -->

    <!-- account:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- account:loading END -->

  </div>
  <!-- account END -->
</template>

<script>
// import store from '@/store';
import ICONS from '../utils/icons';

const {
  iAccountInvoice,
  iAccountPersonalData,
  iAccountEmail,
  iAccountPhone,
  iAccountPassword,
  iAccountQrcode,
  iAccountPin,
  iAccountPaymentMethods,
  iAccountAddress,
  iAccountCustomerCard,
  iAccountDeactivate,
  iAccountLogout,
} = ICONS;

export default {
  name: 'account',

  components: {
    Permalink: () => import('../components/account/Permalink.vue'),
    LoadingDefault: () => import('../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Theme switch
       */
      themeSwitch: false,

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.switch.headline'),
      loadingExcerpt: this.$t('loading.switch.excerpt'),

      /**
       * User
       */
      user: null,
      userName: '',
      userGender: '',
      gender: [
        {
          value: 1,
          label: this.$t('login.gender.male'),
        }, {
          value: 2,
          label: this.$t('login.gender.female'),
        }, {
          value: 3,
          label: this.$t('login.gender.diverse'),
        },
      ],

      /**
       * Permalinks
       */
      permalinks: [
        {
          headline: this.$t('account.invoice.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountInvoice.defaultUrl : iAccountInvoice.greenUrl,
          },
          permalink: '/account/invoice',
        },
        {
          headline: this.$t('account.personaldata.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountPersonalData.defaultUrl : iAccountPersonalData.greenUrl,
          },
          permalink: '/account/personal-data',
        },
        {
          headline: this.$t('account.email.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountEmail.defaultUrl : iAccountEmail.greenUrl,
          },
          permalink: '/account/email',
        },
        {
          headline: this.$t('account.phone.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountPhone.defaultUrl : iAccountPhone.greenUrl,
          },
          permalink: '/account/phone',
        },
        {
          headline: this.$t('account.password.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountPassword.defaultUrl : iAccountPassword.greenUrl,
          },
          permalink: '/account/password',
        },
        {
          headline: this.$t('account.qrcode.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountQrcode.defaultUrl : iAccountQrcode.greenUrl,
          },
          permalink: '/account/qrcode',
        },
        {
          headline: this.$t('account.pin.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountPin.defaultUrl : iAccountPin.greenUrl,
          },
          permalink: '/account/pin',
        },
        {
          headline: this.$t('account.paymentmethods.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountPaymentMethods.defaultUrl : iAccountPaymentMethods.greenUrl,
          },
          permalink: '/account/payment-methods',
        },
        {
          headline: this.$t('account.customercard.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountCustomerCard.defaultUrl : iAccountCustomerCard.greenUrl,
          },
          permalink: '/account/customer-cards',
        },
        {
          headline: this.$t('account.logout.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountLogout.defaultUrl : iAccountLogout.greenUrl,
          },
          permalink: '/account/logout',
        },
        {
          headline: this.$t('account.deactivate.headline'),
          icon: {
            src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountDeactivate.whiteUrl : iAccountDeactivate.whiteUrl,
          },
          permalink: '/account/deactivate',
        },
      ],
    };
  },
  async created() {
    this.user = await this.FETCH_USER();

    if (this.user) {
      this.gender.forEach((g) => {
        if (g.value === this.user.genderID) {
          this.userGender = g.label;
        }
      });
      this.userName = `${this.user.firstName} ${this.user.lastName}`;
    }
  },
  methods: {
    async FETCH_USER() {
      await this.$store.dispatch('setUser');
      return this.$store.getters.user;
    },

    reRenderComponent() {
      this.loadingVisible = true;
      setTimeout(() => {
        // this.$router.go(0);
        this.loadingVisible = false;
      }, 750);
    },
  },
  mounted() {
    // check for active theme
    const htmlElement = document.documentElement;
    const theme = localStorage.getItem('theme');

    if (theme === 'dark') {
      localStorage.setItem('theme', 'dark');
      htmlElement.setAttribute('theme', 'dark');
      this.themeSwitch = true;
    } else {
      localStorage.setItem('theme', 'light');
      htmlElement.setAttribute('theme', 'light');
      this.themeSwitch = false;
    }

    // close $notify from Payback Screen
    this.$notify.closeAll();
  },
  watch: {
    themeSwitch() {
      // add/remove class to/from html tag
      const htmlElement = document.documentElement;

      if (this.themeSwitch) {
        localStorage.setItem('theme', 'dark');
        htmlElement.setAttribute('theme', 'dark');
      } else {
        localStorage.setItem('theme', 'light');
        htmlElement.setAttribute('theme', 'light');
      }
      this.$root.$emit('themeSwitch', this.themeSwitch);

      // not the best solution - update nav icons on theme switch
      this.permalinks[0].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountInvoice.defaultUrl : iAccountInvoice.greenUrl;
      this.permalinks[1].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountPersonalData.defaultUrl : iAccountPersonalData.greenUrl;
      this.permalinks[2].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountEmail.defaultUrl : iAccountEmail.greenUrl;
      this.permalinks[3].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountPassword.defaultUrl : iAccountPassword.greenUrl;
      this.permalinks[4].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountQrcode.defaultUrl : iAccountQrcode.greenUrl;
      this.permalinks[5].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountPin.defaultUrl : iAccountPin.greenUrl;
      this.permalinks[6].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountPaymentMethods.defaultUrl : iAccountPaymentMethods.greenUrl;
      this.permalinks[7].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountAddress.defaultUrl : iAccountAddress.greenUrl;
      this.permalinks[8].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountCustomerCard.defaultUrl : iAccountCustomerCard.greenUrl;
      this.permalinks[9].icon.src = localStorage && localStorage.getItem('theme') === 'light' ? iAccountLogout.defaultUrl : iAccountLogout.greenUrl;
    },
  },
};
</script>
